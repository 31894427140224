<template>

<!-- <section id="contact"> -->
  <div class="usermanual container">
    <br>

    <p class="font-weight-bold">Table of Content</p>
    <ol>

        <li><CLink href="#" @click="orgRegCollapse = !orgRegCollapse" >Organization Registration</CLink></li>
          <CCollapse :show="orgRegCollapse">
            Email following information to admin@signledger.com:
            <ul>
              <li>
                  Organization name
              </li>
              <li>
                  Contact Person - Name, Address, Email, Phone number
              </li>
              <li>
                  Recipient Emails for - Receiving automated monthly order reports
              </li>
              <li>
                 Google Emails of Admin Users for - Pay.SignLedger.com<br>
                 These are the users who will login and use Order.SignLedger admin functionality.
                 SignLedger relies on Google Accounts for user authentication.<br>
                 If users don't have existing Google account, they can create new one at -<br>
                 <a href="https://accounts.google.com/signup/v2/webcreateaccount?flowName=GlifWebSignIn&flowEntry=SignUp">
                    Create New Google Account
                 </a>
              </li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="ordersCollapse = !ordersCollapse" >Sale/Purchase Orders</CLink></li>
          <CCollapse :show="ordersCollapse">
            <ul>
              <li>
                  When user clicks on 'Sale Order' or 'Purchase Order' menu items,<br>
                  by default list of orders for last month is shown.
              </li>
              <li>
                  User can change 'From Date' and 'To Date' and then click 'Get Orders' to get new list of orders.
              </li>
              <li>
                  User can click 'Report' which will open a new browser tab with order report.
              </li>
              <li>
                  To save this report -<br>
                   Right click anywhere on this report and select 'Print'.<br>
                   In 'Destination' select 'Save as PDF'.<br>
              </li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="createOrderCollapse = !createOrderCollapse" >Create New Order</CLink></li>
          <CCollapse :show="createOrderCollapse">
            <ul>
              <li>Click 'New Order' button. It will open a modal window to capture order details.</li>
              <li>Information captured: Customer - Name and Phone, Invoice number, Order Description, Order Amount.</li>
              <li>Click 'Save' to save the data and create new order.</li>
              <li>There is a field at the top - 'Search Customer'.<br>
                  User can type any part of text from customer Name. <br>
                  List of customer names matching the entered names will be displayed.<br>
                  User can select the matching customer from that list.
              </li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="modifyOrderCollapse = !modifyOrderCollapse" >Modify Order</CLink></li>
          <CCollapse :show="modifyOrderCollapse">
            <ul>
              <li>On the main order page, select order by clicking checkbox next to 'Order ID'.</li>
              <li>
                  Click 'Order Details' button. <br>
                  This will open "Order Details" window where user can modify data.
              </li>
              <li>
                  Order Description and Amount can be modified only when the order status is - Created (C).<br>
                  If order is in any other status, user will not be allowed to modify these 2 fields.<br>
                  Click 'Save' to save the order modifications.
              </li>
              <li>
                  'Order Details' page generates and displays 'Tracking Link' for this order.<br>
                  Organizations can give these links to external parties who can then check the order status<br>
                  without logging in the system.
              </li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="trackOrderCollapse = !trackOrderCollapse" >Track Order - Modify Order Status</CLink></li>
          <CCollapse :show="trackOrderCollapse">
            <ul>
              <li>On the main order page, select order by clicking checkbox next to 'Order ID'.</li>
              <li>Click 'Track Order' button. This will open "Order Status" window where user can modify order status.</li>
              <li>User can add 'Comment' to associate with the status change.</li>
              <li>Click 'Save' to save the order status change.</li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="saleStatusesCollapse = !saleStatusesCollapse" >Sale Order - Statuses</CLink></li>
          <CCollapse :show="saleStatusesCollapse">
            <ul>
              <li>Created (C)</li>
              <li>Payment Received (P)</li>
              <li>Order Received at Mfg (M)</li>
              <li>Goods Sent to Transport (T)</li>
              <li>Cancelled (X)</li>
            </ul>
            If organization requires statuses different than the list above, please email to admin@signledger.com
          </CCollapse>
        <br>

        <li><CLink href="#" @click="purchaseStatusesCollapse = !purchaseStatusesCollapse" >Purchase Order - Statuses</CLink></li>
          <CCollapse :show="purchaseStatusesCollapse">
            <ul>
              <li>Created (C)</li>
              <li>Ordered (O)</li>
              <li>Paid (P)</li>
              <li>Received (R)</li>
              <li>Delayed (D)</li>
              <li>Cancelled (X)</li>
            </ul>
            If organization requires statuses different than the list above, please email to admin@signledger.com
          </CCollapse>
        <br>

        <li><CLink href="#" @click="partnersCollapse = !partnersCollapse" >Customers / Suppliers</CLink></li>
          <CCollapse :show="partnersCollapse">
            <ul>
              <li>As orders are entered in the system, <br>
                  Customer and Supplier details are captured automatically from the order details<br>
                  to build Customer and Supplier directory.</li>
              <li>To add new records or to modify existing records, select records by clicking checkbox at the left.</li>
              <li>Select 'Details' button and make required changes.</li>
            </ul>
          </CCollapse>
        <br>

        <li><CLink href="#" @click="extTrackCollapse = !extTrackCollapse" >Order Tracking - by Customers/Suppliers</CLink></li>
          <CCollapse :show="extTrackCollapse">
            <ul>
              <li>All the previous functionality is available to registered admin users of the organization.</li>
              <li>Customers and Suppliers can benefit by getting visibility into organization's supply chain.<br>
                  They can check order status without logging into the system.</li>
              <li>Home Page has this functionality.</li>
              <li>External Parties who knows their 'Order ID' can quickly check order status here.</li>
            </ul>
          </CCollapse>
        <br>

    </ol>




  </div>

  <!-- </section> -->
</template>

<script>
import axios from "axios"

export default {
  name: 'usermanual',
  data () {
    return {
      orgRegCollapse: false,
      ordersCollapse: false,
      createOrderCollapse: false,
      modifyOrderCollapse: false,
      trackOrderCollapse: false,
      saleStatusesCollapse: false,
      purchaseStatusesCollapse: false,
      partnersCollapse: false,
      extTrackCollapse: false,
    }
  },

  methods:{
  },

  components:{
  }
}
</script>


